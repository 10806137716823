import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetImageSource } from "../../services/globalService";
import { GetQuestions, GotoStep, Next, Previous, SaveFeedback, SetLoader } from "../../services/quizService";
import Step1 from "../step1";
import Step2 from "../step2";
import Step3 from "../step3";
import Step4 from "../step4";
import Step5 from "../step5";

const QuizStep = () => {
    const dispatch = useDispatch();

    //static declarations for steps components
    const Components = {
        "Step1": Step1,
        "Step2": Step2,
        "Step3": Step3,
        "Step4": Step4,
        "Step5": Step5
    }

    //classes to apply on each component according to step
    const compClasses = {
        "1": "",
        "2": "leaf-bg s-startquiz startquiz-step2",
        "3": "plant-native-bg startquiz-step3",
        "4": "step-4-bg startquiz-step4",
        "5": "step-5-bg",
    }

    const activeStep = useSelector((state) => state.Quiz.step); //customer current step 
    const questions = useSelector((state) => state.Quiz.questions); //questions for each step
    const feedback = useSelector((state) => state.Quiz.feedback);   //preserved feedback JSON submitted in previous step
    const customId = useSelector((state) => state.Quiz.customId); //unique customer ID
    const loader = useSelector((state) => state.Quiz.loading);
    var ActiveComponent = Components[`Step${activeStep.toString()}`]; //assign current step component to Render

    useEffect(() => {
        // getting questions for step (will be functional if questions are configurable and fetched from API)
        dispatch(GetQuestions(activeStep))
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });

    }, [activeStep])

    // back navigation handler
    const BackBtnHandler = (e) => {
        e.preventDefault()
        dispatch(Previous())
    }

    // save submitted answers for specifice step and proceed to next step
    const NextStep = (data) => {
        dispatch(SetLoader(true))
        data.customId = customId
        dispatch(Next(data))

    }

    // Submit Quiz to SalesForce
    const submitFeedback = () => {
        dispatch(SaveFeedback(feedback))
    }

    // navigate to step 
    const JumpToStep = (step) => {

        if (activeStep > step)
            dispatch(GotoStep(step))
    }

    useEffect(() => {
        dispatch(SetLoader(false))
    }, [activeStep])

    return (

        <div className={(compClasses[activeStep.toString()]) + ((activeStep > 1) ? " direction-column" : "")} >
            {/* main header with back button and Steps Navigation */}
            {activeStep > 1 ?
                <div className="innr-nav">
                    <div className="w-back-btn-box">
                        <a onClick={BackBtnHandler}>
                            {"<"} BACK </a>
                    </div>

                    <div className="slider-wrapper">
                        <div class="progress">
                            <div class="percent" style={{ width: ((activeStep - 2) * 31) + "%" }}></div>
                        </div>

                        <div className="w-progress-br">
                            <ul className="steps">
                                {
                                    Object.keys(Components).map((x, i) => {
                                        return ((i > 0) ? <li onClick={(e) => JumpToStep(i + 1)} key={i} className={"step " + ((i < activeStep - 1) ? "completed " : "") + ((i + 1 == activeStep) ? "active" : "")}><span></span></li> : "")
                                    })}
                            </ul>
                        </div>
                    </div>
                </div> : ""}
            {/* dynamic active step component */}
            <ActiveComponent loader={loader} questions={questions} submitFeedback={submitFeedback} feedback={feedback} Next={NextStep} />
        </div >
    )

}

export default QuizStep;
