const initailState = {
  auth: "false",
  user:{},
  errorCall: "",
  errorRespMsg: "",
};

const Auth = (state = initailState, action) => {
  switch (action.type) {
    case 'user-login':
      return { ...state, auth: true, user: action.payload };
    case 'auth-error':
      return {
        ...state,
        errorCall: action.payload.call,
        errorRespMsg: action.payload.message,
      };
    case 'user-logout':
      localStorage.clear();
      return { ...state, auth: false };
    case 'update-session-state':
      return { ...state, auth: action.payload };
    case 'empty-error':
      return { ...state, errorCall: "", errorRespMsg: "" };
    default:
      return state;
  }
};

export default Auth;
