import { useState } from "react";
import { GetImageSource } from "../../../services/globalService"
import DialogComponent from "../../dialog";


const BehindScience = (props) => {


    const [show, setShow] = useState(false);

    // load behind the science popup
    const PopupHandler = (email) => {
        setShow(true)
    }

    //hide behind the science popup
    const handleClose = (email) => {
        setShow(false)

    }
    return (
        <div>
            <div onClick={PopupHandler} className="w-star-icon-txt">
                <p><img src={GetImageSource("/img/flower-gear.svg")} alt="" /> <span>Behind the science</span></p>
            </div>
            {/* generic dialog layout with component(popup) name to render sent as prop */}
            {show ? <DialogComponent dialogName="BehindScience" handleClose={handleClose} /> : ""}
        </div>
    )
}

export default BehindScience