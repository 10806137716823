import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetImageSource } from "../../services/globalService";
import Loader from "../shared/Loader";


const Step1 = (props) => {

    //Proceed to next step
    const StartQuizHandler = (e)=>{
        e.preventDefault()
        props.Next({})    
    }

    return (

        <div className="startquiz-step1">
            <div className="s-startquiz-left">
                <img src={GetImageSource("/img/gfw-quiz-cover.png")} alt="" />
            </div>
            <div className="s-startquiz-right">
                <div className="s-startquiz-right-content no-bg-img">
                    <h1 className="w-heading">This quick and easy quiz will help you find exactly what you need to plant to make an impact.</h1>
                    <div className="w-prabox mb30">
                        <p>Ready to turn your yard into a native plant garden?</p>
                    </div>
                    <button disabled={props.loader} onClick={StartQuizHandler} className="quiz-btn theme-btn">{props.loader ? <Loader size="20px"></Loader> : "Start Quiz"}</button>
                </div>
            </div>
        </div>
    )

}

export default Step1;
