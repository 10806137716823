import Auth from "./Auth";

import Common from "./common";
import Quiz from "./quiz";
import SalesForce from "./salesForce";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  Auth, //auth reducer (not in use) 
  Common,
  Quiz,
  SalesForce,
});

export default rootReducer;
