import QuizStep from "../../components/quizStep";
import "./Quiz.css";
import React, { useEffect, useState } from "react";
// import Loader from "../../Component/Loader"


const Quiz = () => {

    return (
        // quiz section container 
        <div className="container">
            {/* generic component layout for quiz steps */}
            <QuizStep>
            </QuizStep>
        </div>
    )
}

export default Quiz;
