import { getAnonymousId } from "../services/globalService";

const initailState = {
    step: JSON.parse(localStorage.getItem("quiz-step")) || 1,
    feedback: JSON.parse(localStorage.getItem("quiz-feedback")) || {},
    customId: localStorage.getItem("quiz-custom-id") || getAnonymousId((new Date().toISOString())),
    questions: [],
    loading: true
};


const Quiz = (state = initailState, action) => {
    switch (action.type) {

        //loading actions
        case "set-loading":
            return { ...state, loading: action.payload }
        case "set-question":
            return { ...state, questions: action.payload }


        // saving options selected for each step and setting next active step    
        case "next-step":

            let nextStep = (state.step < 5) ? state.step + 1 : state.step
            localStorage.setItem("quiz-step", nextStep)
            //Object.assign(state.feedback, action.payload)
            localStorage.setItem("quiz-feedback", JSON.stringify(action.payload))
            return { ...state, feedback: action.payload, step: nextStep, user: action.payload };

        // navigating to previous step
        case "previous-step":
            let prevStep = state.step > 1 ? state.step - 1 : state.step
            localStorage.setItem("quiz-step", prevStep)
            return { ...state, step: prevStep, user: action.payload };

        // navigating to required step
        case "set-step":
            return { ...state, step: action.payload };


        default:
            return state;

    }
};

export default Quiz;

