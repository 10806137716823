import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetImageSource, ParseMultipleAnswers, ReturnSelected } from "../../services/globalService";
import { SetLoader } from "../../services/quizService";
import DialogComponent from "../dialog";
import BehindScience from "../shared/BehindScience";
import Loader from "../shared/Loader";


const Step5 = (props) => {
    const [error, setError] = useState("")
    const dispatch = useDispatch()

    // Light Condition Step options with image name 
    let optionsObj = [
       {
           img: "cloudsun",
           content: "less than 3 hours",
           selected: false

       },
        {
            img: "suncolud",
            content: "between 3 and 6 hours",
            selected: false

        },
        {
            img: "sun",
            content: "6 hours or more",
            selected: false

        },

    ]

    let previousFeedback = props.feedback.questions.filter(x => x.name === "q3")[0]
    optionsObj = ReturnSelected(JSON.parse(JSON.stringify(optionsObj)), previousFeedback ? previousFeedback.answer.split(';') : [])


    const [show, setShow] = useState(false);

    const [options, setOptions] = useState(optionsObj)
    let selected = ""
    const ContinueHandler = (e) => {

        e.preventDefault();
        let answers = ParseMultipleAnswers(getSelectedOption(options))
        if (answers) {

            //open email popup
            setShow(true)
            let data = props.feedback
            if (!data.questions) data.questions = []
            data.questions = data.questions.filter(x =>x.name !== "q3")
            data.questions.push({ name :"q3",answer : answers })
            props.Next(data)
        }
        else ShowError()
    }

    const getSelectedOption = (options) => {
        return options.filter(x => x.selected)

    }

    const onOptionSelect = (i) => {
        let newArr = JSON.parse(JSON.stringify(options))
        newArr[i].selected = !newArr[i].selected;
        setOptions(newArr);
    }

    const ShowError = (type = "") => {
        switch (type) {
            case 'multi':
                setError("Multiple selections not allowed")
                break;

            default:
                setError("Please select one option")
                break;
        }

    }
    const HideError = () => {
        setError("")
    }

    // email popup onclose handler
    const handleClose = (email) => {
        setShow(false)
        dispatch(SetLoader(true))
        // if (email){
        let data = props.feedback
        data.email = email ? email : ""
        props.Next(data)
        // } 
        setTimeout(() => {
            //submit feedback
            props.submitFeedback()
        }, 0);

    }

    const OptionMouseOverListener = (i) => {
        let elem = document.getElementById("option-img" + i)
        elem.classList.add("hover-svg");
        elem.classList.remove("wht-svg")

    }

    const OptionMouseLeaveListener = (i) => {
        let elem = document.getElementById("option-img" + i)
        elem.classList.remove("hover-svg");
        elem.classList.add("wht-svg")

    }

    return (
        <div>

            <div className="s-startquiz-right-content align-center h-100vh h-100-percent-tab">
                {props.loader ? <div>
                    <h1 className="w-heading">Growing Your Results...</h1> 
                    <Loader size="30px" />
                </div>
                    : ""}

                {!props.loader ? <div>
                    <h1 className="w-heading">How many hours of direct sunlight does the space <br /> where your're planting receive each day?</h1>
                    <BehindScience />


                    <div className="plant-option mb30">
                        {
                            options.map((opt, index) => {
                                return (
                                    <div id={"option-" + index} key={"option-" + index} onClick={(e) => { e.preventDefault(); onOptionSelect(index) }} className={(opt.selected ? "option-selected" : "") + " w-plant-option-box "}
                                        onMouseOver={(e) => { OptionMouseOverListener(index) }} onMouseLeave={(e) => { OptionMouseLeaveListener(index) }}
                                    >
                                        <img id={"option-img" + index} src={GetImageSource("/img/" + opt.img + ((opt.selected ? "-color" : "")) + ".png")} className={!options[selected] ? "wht-svg" : "hover-svg"} alt="" />
                                        <p>{opt.content}</p>

                                    </div>
                                )
                            })
                        }
                    </div>
                    {error ? <p className='error'>{error}</p> : ""}
                    <button disabled={props.loader} onClick={ContinueHandler} className={"quiz-btn " + (getSelectedOption(options).length ? "theme-btn" : "")}>{props.loader ? <Loader size="20px"></Loader> : "Continue"}</button>
                </div> : ""}

            </div>
            {show ? <DialogComponent dialogName="Email" handleClose={handleClose} /> : ""}

        </div>

    )
}

export default Step5;
