import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import globalService, { GetImageSource } from "../../services/globalService";

const BehindSciencePopup = (props) => {

    return (

        <div className="behind-the-science">  
         <div className="bts-bg-image"></div>
         <div className="padding">
         <div className="w-star-icon-txt">
                    <p><img src={GetImageSource("/img/flower-gear.svg")} alt="" /> <span>Behind the science</span></p>
                 </div>  
            <div className="popup-header mb15">
                <h1 className="w-heading">Lorem ipsum dolor sit amet consectetur.</h1>
                <span className="close toggle" onClick={(e) => props.handleClose()}>close</span>
            </div>
            <div className="popup-body">
                <div className="w-prabox">
                    <p>Lorem ipsum dolor sit amet, consectetur quam id adipiscing elit, sed do eiusmod tempor in vitae incididunt ut labore et dolore magna aliqua. Vel pretium lectus quam id leo in vitae. Lorem ipsum dolor sit amet consectetur. Interdum consectetur libero id faucibus nisl tincidunt eget.</p>
                </div>
            </div>
            </div>
        </div>
    )
}

export default BehindSciencePopup;
