
const initailState = {
    questions:[],
    quizFeeback:[]
};

const SalesForce = (state = initailState, action) => {
    switch (action.type) {

        
        default:
            return state;

    }
};

export default SalesForce;

