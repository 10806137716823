
const initailState = {

};

const Common = (state = initailState, action) => {

    switch (action.type) {

        default:
            return state;
    }
};

export default Common;
