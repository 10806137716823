const uuid = require('uuid');

module.exports = {

    RegexExpressions: {
        zip: /\b\d{5}\b/g,
        email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    },
    ValidatePattern: function (type, value) {
        return new RegExp(this.RegexExpressions[type]).test(value);
    },

    GetImageSource: (url) => {
        return ((process.env.REACT_APP_MODE.toLowerCase() == 'prod') ? (process.env.REACT_APP_URL + ('')) : "") + url;
    },
    Debounce: (func, delay) => {
        let debounceTimer
        return function () {
            const context = this
            const args = arguments
            clearTimeout(debounceTimer)
            debounceTimer = setTimeout(() => func.apply(context, args), delay)
        }
    },

    ParseMultipleAnswers: (options) => {
        let answers = "";
        options.map((x, i) => {
            answers += ((i > 0) ? ";" : "") + x.content
        })
        return answers
    },
    ReturnSelected: (options, selected) => {
        return options.map(x => {
            if (selected.indexOf(x.content.toLowerCase()) !== -1)
                x.selected = true;
            return x
        })
    },

    getAnonymousId: (str) => {
        let hash = 0;
        let char = ""
        if (str.length == 0) return hash;
        let arr = str.split("")
        arr.map((x, i) => {
            char = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash = hash & hash; // Convert to 32bit integer
        })
        hash = uuid.v4() + "." + hash
        localStorage.setItem("quiz-custom-id", hash)
        return hash;
    }


}