import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import globalService, { GetImageSource } from "../../services/globalService";

const EmailPopup = (props) => {
    const [error, setError] = useState("")
    const elem = useRef();
    const btnElem = useRef();

    const ContinueHandler = (e) => {
        e.preventDefault()
        if (elem.current.value && !globalService.ValidatePattern(['email'], elem.current.value)) {
            ShowError("invalid")
            return
        }
        else HideError()

        props.handleClose(elem.current.value)

    }

    const ShowError = (type = "empty") => {
        switch (type) {
            case 'invalid':
                setError("invalid Email")
                break;

            default:
                setError("Please enter your Email")
                break;
        }

    }
    const HideError = () => {
        setError("")
    }

    useEffect(()=>{
        if(elem.current) {
            elem.current.value = localStorage.getItem("quiz-email");
            InputHandler()
        } 
    },[elem])

    const InputHandler = () => {
        if (elem.current.value) btnElem.current.classList.add("theme-btn")
        else btnElem.current.classList.remove("theme-btn")
    }

    return (
        <div className="email-popup">
            <div className="popup-header mb15">
                <h1 className="w-heading">What is your <br /> email address?</h1>
                <span className="close toggle" onClick={(e) => props.handleClose()}>close</span>
            </div>
            <div className="popup-body mb30">
                <input onChange={InputHandler}  ref={elem} type="email" className="input-field mb15 full-width-field" placeholder="Your Email" />
                <a ref={btnElem} className="quiz-btn full-width-field" onClick={ContinueHandler}>See My Results</a>
                {error ? <p className='error'>{error}</p> : ""}
                <div className="w-prabox small-font">
                    <p>By signing up, you agree to receive email marketing.</p>
                </div>
            </div>
            <div className="w-prabox skip">
                <p className="toggle pointer " onClick={(e) => props.handleClose()}>skip</p>
            </div>
        </div>
    )
}

export default EmailPopup;
