import './loader.css'

// Spinner Loader with adjustable height
const Loader = (props) => {
    return (
        <div className='loader-container'>
            <div className="spn-loader" style={{ width: props.size ? props.size : "20px", height: props.size ? props.size : "20px", margin: props.margin ? props.margin : "auto" }}></div>
        </div>
    )
}

export default Loader