import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import globalService, { GetImageSource } from "../../services/globalService";
import { GetAvailability, SetLoader } from "../../services/quizService";
import BehindScience from "../shared/BehindScience";
import Loader from "../shared/Loader";


const Step2 = (props) => {
    //const [error, setError] = useState("")
    const dispatch = useDispatch()
    const zipElem = useRef();
    const btnElem = useRef();
    const errorElem = useRef();

    //Proceed to next step, saving currently answered feedback 
    const ContinueHandler = async (e) => {
        e.preventDefault()
        e.stopPropagation()
        let zipError = false

        //validating zip code
        if (!zipElem.current.value) {
            ShowError();
            zipError = true
        }
        else {
            if (!globalService.ValidatePattern(['zip'], zipElem.current.value )) {
                ShowError("invalid")
                zipError = true
            }
            else {
                dispatch(SetLoader(true))
                let result = await GetAvailability(zipElem.current.value)

                if (!result.data.product_available_sku_list?.length) {
                    ShowError("unavailable")
                    zipError = true
                    dispatch(SetLoader(false))
                }


            }
        }
        if (zipError) return
        else HideError()

        //if no error proceed
        e.preventDefault()
        let data = props.feedback
        data.zipCode = zipElem.current.value
        props.Next(data)
    }


    // case specific error handling f
    const ShowError = (type = "empty") => {
        let zipError = ""
        switch (type) {
            case 'invalid':
                zipError = "Invalid Zip Code"
                break;

            case 'unavailable':
                zipError = `We’re sorry; we don’t yet offer plants for your area. <a target="_blank" href="/pages/out-of-region">Learn more </a>or try a different zip code`
                break;

            default:
                zipError = "Please enter your Zip Code"
                break;
        }
        //setError(error)
        errorElem.current.innerHTML = zipError
        errorElem.current.classList.remove('hide')

    }

    

    //hide error element
    const HideError = () => {
        // setError("")
        errorElem.current.classList.add('hide')
        errorElem.current.innerHTML = ""
    }

    // load previously entered zip from cookie
    useEffect(() => {
        if (zipElem.current) {
            zipElem.current.value = localStorage.getItem("zipCode");
            InputHandler()
        }

    }, [zipElem])


    // Zip input event handler 
    const InputHandler = () => {
        if (zipElem.current.value) btnElem.current.classList.add("theme-btn")
        else btnElem.current.classList.remove("theme-btn")
    }

    return (
        <div className="s-startquiz-right-content align-center">
            <h1 className="w-heading">Where will you be planting?</h1>
            <div className="w-prabox">
                <p>Our plants are selected and grown specifically for where <br />they’ll be planted, to help local wildlife and the planet. </p>
            </div>

            {/* behind the science popup */}
            <BehindScience />
            <input ref={zipElem} onChange={InputHandler} type="text" className="input-field" placeholder="Your Zip Code" maxlength="5" onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();} }} />
            <p ref={errorElem} className='error'></p>
            <button disabled={props.loader} ref={btnElem} onClick={ContinueHandler} className={"quiz-btn"}>{props.loader ? <Loader size="20px"></Loader> : "Continue"}</button>
        </div >

    )
}

export default Step2;
