import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetImageSource, ParseMultipleAnswers, ReturnSelected } from "../../services/globalService";
import BehindScience from "../shared/BehindScience";
import Loader from "../shared/Loader";


const Step4 = (props) => {
    const [error, setError] = useState("")

    // Space Condition Step options with image name 
    let optionsObj = [
        {
            img: "multiplecontainerspots",
            content: "multiple containers/pots",
            selected: false

        },
        {
            img: "spacearound",
            content: "small in-ground space around 4x6 feet",
            selected: false

        },
        {
            img: "leaf",
            content: "medium-to-large in-ground space around 8x10 feet",
            selected: false

        },
        {
            img: "in-ground",
            content: "larger in-ground space around 12x20 feet",
            selected: false

        },


    ]

    // loading previous options selected if unsubmited earlier and not submitted
    let previousFeedback = props.feedback.questions.filter(x => x.name === "q2")[0]

    // mapping on current options
    optionsObj = ReturnSelected(JSON.parse(JSON.stringify(optionsObj)), previousFeedback ? previousFeedback.answer.split(';') : [])


    const [options, setOptions] = useState(optionsObj)
    let selected = ""


    //proceed to next step
    const ContinueHandler = (e) => {
        e.preventDefault()
        let answers = ParseMultipleAnswers(getSelectedOption(options))
        if (answers) {
            let data = props.feedback
            if (!data.questions) data.questions = []

            data.questions = data.questions.filter(x => x.name !== "q2")
            data.questions.push({ name: "q2", answer: answers })
            props.Next(data)
        }
        else ShowError()
    }

    // option selection handler
    const onOptionSelect = (i) => {
        let newArr = JSON.parse(JSON.stringify(options))
        newArr[i].selected = !newArr[i].selected;
        setOptions(newArr);
    }

    // case specific error handler
    const ShowError = (type = "") => {
        switch (type) {
            case 'multi':
                setError("Multiple selections not allowed")
                break;

            default:
                setError("Please select one option")
                break;
        }

    }

    // hide error element
    const HideError = () => {
        setError("")
    }

    // Options image hover handler 
    const OptionMouseOverListener = (i) => {
        let elem = document.getElementById("option-img" + i)
        elem.classList.add("hover-svg");
        elem.classList.remove("wht-svg")

    }

    const OptionMouseLeaveListener = (i) => {
        let elem = document.getElementById("option-img" + i)
        elem.classList.remove("hover-svg");
        elem.classList.add("wht-svg")

    }

    const getSelectedOption = (options) => {
        return options.filter(x => x.selected)

    }

    return (
        <div className="s-startquiz-right-content align-center">
            <h1 className="w-heading">How much space do you have for this wildlife garden?</h1>
            <BehindScience />

            <div className="plant-option mb30">
                {
                    options.map((opt, index) => {
                        return (
                            <div key={"option-" + index} id={"option-" + index} onClick={(e) => { e.preventDefault(); onOptionSelect(index) }} className={(opt.selected ? "option-selected" : "") + " w-plant-option-box "}
                                onMouseOver={(e) => { OptionMouseOverListener(index) }} onMouseLeave={(e) => { OptionMouseLeaveListener(index) }}
                            >
                                <img id={"option-img" + index} src={GetImageSource("/img/" + opt.img + ((opt.selected ? "-color" : "")) + ".png")} className={!options[selected] ? "wht-svg" : "hover-svg"} alt="" />
                                <p>{opt.content}</p>

                            </div>
                        )
                    })
                }
            </div>
            {error ? <p className='error'>{error}</p> : ""}
            <button disabled={props.loader} onClick={ContinueHandler} className={"quiz-btn " + (getSelectedOption(options).length ? "theme-btn" : "")}>{props.loader ? <Loader size="20px"></Loader> : "Continue"}</button>

        </div >

    )
}

export default Step4;
