import EmailPopup from "../EmailPopup";
import Modal from 'react-bootstrap/Modal'
import BehindSciencePopup from "../behindTheSciencePopup";
function DialogComponent(props) {

    // Declarations of Popup components & assigning names
    const Components = {
        "EmailPopup": EmailPopup,
        "BehindSciencePopup": BehindSciencePopup
    }

    // pop up to show
    let ActivePopup = Components[`${props.dialogName.toString()}Popup`];

    return (

        //pop up layout
        <div>
            <div className="overlay"></div>
            <div id="myPopup" className="popup">
                {/* selected popup to show */}
                <ActivePopup {...props} />
            </div>
        </div>
        /* <Modal show={true} onHide={props.handleClose}>
            {props.heading ?
                <Modal.Header closeButton>
                    <Modal.Title>{props.heading}</Modal.Title>
                </Modal.Header> : ""
            }
            <Modal.Body> */
        /* </Modal.Body>
           {props.footer ?
               <Modal.Footer>
                   <button variant="secondary" onClick={props.handleClose}>
                       Close
                   </button>
                   <button variant="primary" onClick={props.handleClose}>
                       Save Changes
                   </button>
               </Modal.Footer> : ""}
       </Modal> */
    );
}

export default DialogComponent

