import axios from "axios";
import { ApiConfig, InitialApiRoute, SalesForceApiRoute } from "./../config/apiConfig";

const dummy = {
    "Step1": [
        {
            name: 'q1',
            options: ["a", "b", "c"],
            multiple: true
        },
        {
            name: 'q2',
            options: ["d", "e", "f"],
            multiple: false
        }

    ],
    "Step2": [
        {
            name: 'q3',
            options: ["a", "b", "c"],
            multiple: true
        },
        {
            name: 'q4',
            options: ["d", "e", "f"],
            multiple: false
        }

    ]
}

// Get dynamic questions
const GetQuestions = (step) => {
    return (dispatch) => {

        dispatch({
            type: 'set-question',
            payload: dummy["Step" + step],
        });
    };
};

// Next Step dispatcher
const GotoStep = (step = 1) => {
    return (dispatch) => {
        dispatch({
            type: 'set-step',
            payload: step
        });
    };
}

// previous Step dispatcher
const Next = (feedback = {}) => {
    return (dispatch) => {
        dispatch({
            type: 'next-step',
            payload: feedback
        });
    };
};

// set loading state
const SetLoader = (state = false) => {
    return (dispatch) => {
        dispatch({
            type: 'set-loading',
            payload: state
        });
    };
};

// send feedback to SF
const PostSurvey = async (feedback) => {
    try {
        return await axios.post(`${SalesForceApiRoute}/gfw/services/apexrest/ShopifyQuestionnaire/`, feedback, ApiConfig)

    } catch (error) {
        return undefined
    }

}

// Check availability of plants against zip code 
const GetAvailability = async (zipCode) => {
    try {
        return await axios.get(`${SalesForceApiRoute}/gfw/services/apexrest/ShopifyZipSearch?zipcode=${zipCode}`, ApiConfig)
    } catch (error) {
        return undefined
    }

}

const SaveFeedback = (feedback = {}) => {
    return async (dispatch) => {
        try {
            let promises = []
            if (feedback) promises.push(PostSurvey(feedback))
            // Preserving Feedback for filteration

            localStorage.setItem("customer-feedback", JSON.stringify(feedback))
            localStorage.removeItem("quiz-feedback")
            let previousData = JSON.parse(localStorage.getItem("quiz-untracked"))

            // submitting anonymous feedback if email present
            if (previousData && feedback.email) {
                previousData.email = feedback.email
                promises.push(PostSurvey(previousData))
                localStorage.removeItem("quiz-untracked")

            }
            await Promise.all(promises)

            // reset quiz app state
            localStorage.setItem("quiz-step", 1)
            if (!feedback.email) {
                localStorage.setItem("quiz-untracked", JSON.stringify(feedback))
            }
            else localStorage.setItem("quiz-email", feedback.email)
            if (feedback.zipCode) localStorage.setItem("zipCode", feedback.zipCode)
            if (promises[0]) window.location.href = process.env.REACT_APP_REDIRECT ? process.env.REACT_APP_REDIRECT : "/collections/all-products?quizFilter=true"

        } catch (error) {

        }
    }
};
;
const Previous = () => {
    return (dispatch) => {
        dispatch({
            type: 'previous-step'
        });
    };
};



export {
    SetLoader,
    GetQuestions,
    Next,
    Previous,
    SaveFeedback,
    GotoStep,
    GetAvailability
};

