const InitialApiRoute = process.env.REACT_APP_URL;
const SalesForceApiRoute = process.env.REACT_APP_SALES_FORCE_API;

const ApiConfig = {
  "Content-Type": "application/json",
};

const ProtectedApiConfig = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-Type": "application/json",
  },
};

export { ApiConfig, InitialApiRoute, ProtectedApiConfig,SalesForceApiRoute };