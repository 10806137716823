import logo from './/assets/logo.svg';
import './App.css';
import Quiz from './pages/quiz';

//main container
function App() {
  return (
    <div className="App">
      <Quiz></Quiz>
    </div>
  );
}

export default App;
